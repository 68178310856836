
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUILinearProgressBar from "@/views/resources/documentation/element-ui/data/progress/LinearProgressBar.vue";
import EUIInternalPercentage from "@/views/resources/documentation/element-ui/data/progress/InternalPercentage.vue";
import EUICustomColor from "@/views/resources/documentation/element-ui/data/progress/CustomColor.vue";
import EUICircularProgressBar from "@/views/resources/documentation/element-ui/data/progress/CircularProgressBar.vue";
import EUIDashboardProgressBar from "@/views/resources/documentation/element-ui/data/progress/DashboardProgressBar.vue";
import EUICustomizedContent from "@/views/resources/documentation/element-ui/data/progress/CustomizedContent.vue";

export default defineComponent({
  name: "progress",
  components: {
    EUILinearProgressBar,
    EUIInternalPercentage,
    EUICustomColor,
    EUICircularProgressBar,
    EUIDashboardProgressBar,
    EUICustomizedContent
  },
  setup() {
    setCurrentPageTitle("Progress");
  }
});
